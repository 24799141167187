import { lazy } from 'react'
import { Loading } from '@/components/Loading'

const handleCatchChunkError = () => {
  window.location.reload()

  return { default: Loading }
}

export const Login = lazy(() =>
  // @ts-ignore
  import(/* webpackChunkName: "Login" */ '@/pages/Login')
    .then(({ Login }) => ({ default: Login }))
    .catch(handleCatchChunkError),
)

export const Payments = lazy(() =>
  // @ts-ignore
  import('@/pages/Payments').then(({ Payments }) => ({ default: Payments })).catch(handleCatchChunkError),
)

export const Groups = lazy(() =>
  // @ts-ignore
  import('@/pages/Groups').then(({ Groups }) => ({ default: Groups })).catch(handleCatchChunkError),
)

export const GroupInfo = lazy(() =>
  // @ts-ignore
  import('@/pages/Groups/GroupInfo').then(({ GroupInfo }) => ({ default: GroupInfo })).catch(handleCatchChunkError),
)

export const Profile = lazy(() =>
  // @ts-ignore
  import('@/pages/Profile').then(({ Profile }) => ({ default: Profile })).catch(handleCatchChunkError),
)

export const Rating = lazy(() =>
  // @ts-ignore
  import('@/pages/Rating').then(({ Rating }) => ({ default: Rating })).catch(handleCatchChunkError),
)

export const Dashboard = lazy(() =>
  // @ts-ignore
  import('@/pages/Dashboard').then(({ Dashboard }) => ({ default: Dashboard })).catch(handleCatchChunkError),
)

export const Pointers = lazy(() =>
  // @ts-ignore
  import('@/pages/StudentStatistic')
    .then(({ StudentStatistic }) => ({ default: StudentStatistic }))
    .catch(handleCatchChunkError),
)

export const Chapter = lazy(() =>
  // @ts-ignore
  import('@/pages/Chapter').then(({ Chapter }) => ({ default: Chapter })).catch(handleCatchChunkError),
)

export const StudentStatisticsMore = lazy(() =>
  // @ts-ignore
  import('@/pages/StudentStatisticsMore')
    .then(({ StudentStatisticsMore }) => ({ default: StudentStatisticsMore }))
    .catch(handleCatchChunkError),
)

export const GamificationOffer = lazy(() =>
  // @ts-ignore
  import('@/pages/GamificationOffer')
    .then(({ GamificationOffer }) => ({ default: GamificationOffer }))
    .catch(handleCatchChunkError),
)

export const Shop = lazy(() =>
  // @ts-ignore
  import('@/pages/Shop').then(({ Shop }) => ({ default: Shop })).catch(handleCatchChunkError),
)

export const Product = lazy(() =>
  // @ts-ignore
  import('@/pages/Shop/Product').then(({ Product }) => ({ default: Product })).catch(handleCatchChunkError),
)

export const Possession = lazy(() =>
  // @ts-ignore
  import('@/pages/Shop/Possession/Product')
    .then(({ Possession }) => ({ default: Possession }))
    .catch(handleCatchChunkError),
)

export const Certificates = lazy(() =>
  // @ts-ignore
  import('@/pages/Certificates').then(({ Certificates }) => ({ default: Certificates })).catch(handleCatchChunkError),
)

export const CertificateInfo = lazy(() =>
  // @ts-ignore
  import('@/pages/Certificates/CertificateInfo')
    .then(({ CertificateInfo }) => ({ default: CertificateInfo }))
    .catch(handleCatchChunkError),
)

export const CertificateMore = lazy(() =>
  // @ts-ignore
  import('@/pages/CertificateMore')
    .then(({ CertificateMore }) => ({ default: CertificateMore }))
    .catch(handleCatchChunkError),
)

export const PublicCertificate = lazy(() =>
  // @ts-ignore
  import('@/pages/Certificates/PublicCertificate')
    .then(({ PublicCertificate }) => ({ default: PublicCertificate }))
    .catch(handleCatchChunkError),
)

export const PublicCertificateHomework = lazy(() =>
  // @ts-ignore
  import('@/pages/CertificateMore/CertificateHomework')
    .then(({ CertificateHomework }) => ({ default: CertificateHomework }))
    .catch(handleCatchChunkError),
)

export const Notifications = lazy(() =>
  // @ts-ignore
  import('@/pages/Notifications')
    .then(({ Notifications }) => ({ default: Notifications }))
    .catch(handleCatchChunkError),
)

export const NotificationSingle = lazy(() =>
  // @ts-ignore
  import('@/pages/Notifications/NotificationSingle')
    .then(({ NotificationSingle }) => ({ default: NotificationSingle }))
    .catch(handleCatchChunkError),
)

export const PublicContract = lazy(() =>
  // @ts-ignore
  import('@/pages/PublicContract')
    .then(({ PublicContract }) => ({ default: PublicContract }))
    .catch(handleCatchChunkError),
)
