import { TStage } from './types'

const appStage = process.env.REACT_APP_STAGE as string

export enum Endpoints {
  Base = '/api/student',
  RefreshToken = '/auth/refresh-token',
  Initial = '/initial',
  Logout = '/logout',
  Profile = '/profile',
  ProfileChangeLogin = '/profile/change-login',
  ProfileChangePassword = '/profile/change-password',
  UploadImageProfile = '/upload/student-profile',
  SignIn = '/auth/sign-in',
  Payments = '/payments',
  Groups = '/groups',
  GroupInfo = '/groups/{id}/lessons',
  Rating = '/awards/statistics',
  Files = '/files',
  LevelsByCategory = '/levels/all',
  homeworkChats = '/homework-chats',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  GroupLesson = '/groups/{id}/lessons',
  GroupLessonRating = '/groups/lesson/rating',
  GroupHomeworkChats = '/homework-chat/{groupId}/homework-chats',
  HomeworkFileUpload = '/upload/homework-attachments',
  HomeworkSend = '/homework-chat/create',
  Categories = '/categories',
  Awards = '/awards',
  AwardsStatistic = '/awards/statistic',
  AwardsByDasboard = '/awards/dashboard',
  LessonAwards = '/lessons/last-lesson',
  Courses = '/courses',
  StatisticsMethods = '/awards/methods',
  LessonsByGroup = '/lessons/by-group',
  LessonVideoNameByGroup = 'lessons/videos-by-lesson',
  SenderLog = '/sender/log',
  Tus = '/tus',
  GamificationOffer = '/oferta/get-oferta',
  GamificationOfferApprove = '/oferta/approve-oferta',
  Offices = '/offices',
  HomeworkChatUpdate = '/homework-chat/update',
  Notifications = '/notifications',
  Count = '/count',
  GetAll = '/get-all',
  Read = '/read',
  Exams = '/exams',
  Chats = '/chats',
  Submit = '/submit',
  Students = '/students',
  LastVideo = '/videos/last-video',
  LessonDays = '/lessons/days',
  LessonsByDate = '/lessons/by-date',
  Store = '/store',
  Items = '/items',
  Transactions = '/transactions',
  Certificates = '/certificates',
  Detail = '/detail',
  AuthLogOut = '/auth/logout',
  Settings = '/settings',
  All = '/all',
  Contracts = '/contracts/public',
  ContractSentOtp = '/contracts/sendOtp',
  ContractAccept = '/contracts/accept',
  MyContracts = '/contracts/mine',
  FilterObjects = '/awards/filter-objects',
  HomeworkChatSubmissions = '/homework-chat/submissions',
}

const stages: Record<string, TStage> = {
  dev: {
    apiUrl: 'https://dev.api-erp.najotedu.uz',
    cdnHost: 'https://pub-6cf5718e638b4ca58e9bfea8246041c0.r2.dev',
    hhUrl: 'https://anvar.t8s.ru/',
  },
  test: {
    apiUrl: 'https://test.erp.api.najotedu.uz',
    cdnHost: 'https://pub-b82ce0cac83446b5b7cd27dd71348f77.r2.dev',
    hhUrl: 'https://anvaar.t8s.ru/',
  },
  prod: {
    apiUrl: 'https://erp.api.najottalim.uz',
    cdnHost: 'https://pub-7be1d45c4a744f86846c80e90df909eb.r2.dev',
    hhUrl: 'https://najotedu.t8s.ru/',
  },
}

export const stage = stages[appStage]
