export const layoutDictionary = {
  noStay: "Yo'q",
  yesGetOut: 'Ha',
  dashboard: 'Bosh sahifa',
  groups: 'Guruhlarim',
  payments: "To'lovlarim",
  settings: 'Sozlamalar',
  rating: 'Reyting',
  pointer: "Ko'rsatgichlarim",
  shop: 'Do`kon',
  certificates: 'Sertifikatlarim',
}
