import { Suspense } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Loading } from '@/components'
import { ROUTES } from '@/constants'
import { BasicLayout } from '@/modules/BasicLayout'
import { useStores } from '@/stores'
import {
  CertificateInfo,
  CertificateMore,
  Certificates,
  Chapter,
  Dashboard,
  GamificationOffer,
  GroupInfo,
  Groups,
  Login,
  Notifications,
  NotificationSingle,
  Payments,
  Pointers,
  Possession,
  Product,
  Profile,
  PublicCertificate,
  PublicCertificateHomework,
  PublicContract,
  Rating,
  Shop,
  StudentStatisticsMore,
} from './lazy'
import { PrivateRoute } from './PrivateRoute'
import { ProtectedRouteOffer } from './PrivateRouteOffer'
import { PublicRoutes } from './PublicRoute'

export const Router = observer(() => {
  const { authStore, profileStore, gamificationOfferStore } = useStores()

  const isRedirectToOfferPage = Boolean(gamificationOfferStore.gamificationOffer && !profileStore.student?.approvedDate)
  const isOfferChecked = Boolean(gamificationOfferStore.gamificationOffer && profileStore.student?.approvedDate)

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<PrivateRoute isAuth={authStore.isAuth} isRedirectToOfferPage={isRedirectToOfferPage} />}>
          <Route
            element={
              <BasicLayout>
                <Outlet />
              </BasicLayout>
            }
          >
            <Route index path={ROUTES.profile} element={<Profile />} />
            <Route path={ROUTES.rating} element={<Rating />} />
            <Route path={ROUTES.payments} element={<Payments />} />
            <Route index path={ROUTES.groups} element={<Groups />} />
            <Route index path={ROUTES.dashboard} element={<Dashboard />} />
            <Route path={ROUTES.groupInfo} element={<GroupInfo />} />
            <Route path={ROUTES.profile} element={<Profile />} />
            <Route path={ROUTES.groupChapter} element={<Chapter />} />
            <Route path={ROUTES.pointers} element={<Pointers />} />
            <Route path={ROUTES.shop} element={<Shop />} />
            <Route path={ROUTES.product} element={<Product />} />
            <Route path={ROUTES.myPossessions} element={<Possession />} />
            <Route path={ROUTES.notifications} element={<Notifications />} />
            <Route path={ROUTES.notificationsSingle} element={<NotificationSingle />} />
            <Route path={ROUTES.errorUrl} element={<h1>Empty</h1>} />
            <Route path={ROUTES.pointerStudentStatisticsMore} element={<StudentStatisticsMore />} />
            <Route path={ROUTES.certificates} element={<Certificates />} />
            <Route path={ROUTES.certificatesSingle} element={<CertificateInfo />} />
            <Route path={ROUTES.profileGamificationOfferApproved} element={<GamificationOffer />} />
          </Route>

          <Route element={<ProtectedRouteOffer isOfferChecked={isOfferChecked} />}>
            <Route index path={ROUTES.gamificationOffer} element={<GamificationOffer />} />
          </Route>
        </Route>
        <Route element={<PublicRoutes isAuth={authStore.isAuth} />}>
          <Route path={ROUTES.login} element={<Login />} />
        </Route>
        <Route path={ROUTES.publicContract} element={<PublicContract />} />
        <Route element={<CertificateMore />} path={ROUTES.certificateMore} />
        <Route element={<PublicCertificate />} path={ROUTES.publicCertificate} />
        <Route element={<PublicCertificateHomework />} path={ROUTES.certificateHomework} />
      </Routes>
    </Suspense>
  )
})
