import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const TIME_FORMAT = 'HH:mm'
const TIME_FULL_FORMAT = 'HH:mm:ss'

const DATE_FORMAT = 'DD MMMM, YYYY'
const FULL_DATE_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
const normalizedFormat = 'YYYY-MM-DD'
const AWARDS_FORMAT = 'DD MMMM, YYYY'

export const getDateFormat = (date: string) => dayjs(date).format()

const deadlineFormat = `DD MMMM, YYYY ${TIME_FORMAT}`

export const dateFormat = (date?: string | Date) => {
  if (!date) return '-'

  return dayjs(date).format(DATE_FORMAT)
}

export const getFullDateFormat = (date?: string) => {
  if (!date) return '-'

  return dayjs(date).format(FULL_DATE_FORMAT)
}

export const getAwardsDateFormat = (date: string) => dayjs(date).format(AWARDS_FORMAT)

export const getDateFormatOfStudent = (date: string | Date) => dayjs(date).format()

export const getPaymentDate = (date: string, hour: number) => dayjs(date).add(hour, 'hours').format(FULL_DATE_FORMAT)

export const secondFormatter = (date: string) => date?.slice(0, 5)

export const deadlineFormatter = (date: string) => {
  if (!date) {
    return '-'
  }

  return dayjs(date).utc().add(5, 'hour').format(deadlineFormat)
}

const currentYear = new Date().getFullYear()

export const userMinBirthYear = currentYear - 3

export const normalizeFormatDate = (date: string) => {
  if (!date) return

  return dayjs(date, normalizedFormat).format(DATE_FORMAT)
}

export const getFormattedDateFromParams = (date: string | Date | Dayjs) => {
  const dayjsDate = dayjs(date)

  return dayjsDate.isValid() ? dayjsDate.format(AWARDS_FORMAT) : ''
}

export const getCalendarDateFormatter = (date: string | Dayjs) => {
  if (!date) return

  return dayjs(date).format(normalizedFormat)
}

export const getCurrentDate = () => dayjs().format(normalizedFormat)

export const hourFormatter = (time: string) => dayjs(time, TIME_FULL_FORMAT).format(TIME_FORMAT)
