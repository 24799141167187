export const ROUTES = {
  home: '/',
  profile: '/profile',
  errorUrl: '/*',
  dashboard: '/',
  login: '/login',
  register: '/register',
  pointers: '/pointers',
  payments: '/payments',
  groups: '/my-groups',
  groupInfo: '/my-groups/:groupId/staff/:staffId',
  groupChapter: '/my-groups/chapters/:groupId/lesson/:lessonId/staff/:staffId',
  rating: '/rating',
  certificates: '/certificates',
  certificatesSingle: '/certificates/:id',
  certificateMore: '/certificate/more/:id',
  certificateHomework: '/certificate/more/:id/chat/:chatId/homework/:homeworkId',
  publicCertificate: '/public/certificate/:id',
  publicContract: '/public/contract/:id',
  notifications: '/notifications',
  notificationsSingle: '/notifications/more/:id',
  pointerStudentStatisticsMore: '/pointers/:categoryId/:methodId',
  gamificationOffer: '/gamification-offering',
  profileGamificationOfferApproved: '/profile/gamification-offering-approved',
  shop: '/shop',
  product: '/shop/:id',
  myPossessions: '/shop/possessions/:id',
} as const
